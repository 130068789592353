import React from 'react';
import './hero.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import image1 from '../../assets/3449736_bratwurst.jpg';
import image2 from '../../assets/2631734_hafen-absberg_02.jpg';
import image3 from '../../assets/3449732_biergarten-tafel.jpg';
import image4 from '../../assets/fetch-4957501_1920.jpg'
import Navbar from '../navbar/navbar';


const App = () => {
  return (
    <div>
        
      <div className="hero">

        <div className="hero-content">
          <h1 className='text-center'>Willkommen am Wasser</h1>
          <p>Ihre Gaststätte auf der Badehalbinsel Absberg.</p>
          <a href="#groups" className="cta-button" style={{ textDecoration: 'none' }}>Gruppe? Hier klicken!</a>
        </div>
      </div>
      </div>
  
  );
};

export default App;