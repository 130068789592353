import React, { useEffect } from 'react';
import './welcome.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaDog, FaBirthdayCake, FaBus } from 'react-icons/fa';
import image1 from '../../assets/3449736_bratwurst.jpg';
import image2 from '../../assets_stock/istockreisebus.jpg';
import image3 from '../../assets/3449732_biergarten-tafel.jpg';
import image4 from '../../assets_stock/istockhunde.jpg';
import image5 from '../../assets_stock/stock-party.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Welcome = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,  // Dauer der Animation in Millisekunden
      once: true,      // Animation nur einmal abspielen
    });
  }, []);

  const settings = {
    dots: false,  // Navigationsdots anzeigen
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,  // Automatisches Scrollen aktivieren
    autoplaySpeed: 6000,  // Zeit in Millisekunden zwischen den Slides
    arrows: false,  // Pfeile ausblenden
  };
  return (
    <section id="freiluft" className="welcome-section">
      <div className="container">
        <div className="row text-center" data-aos="fade-up">
          <div className="col-12">
            <h2 className="section-title1">Gaststätte „Biergarten Zum Hafen“ – willkommen!</h2>
            <p className="intro-text">Direkt auf der Badehalbinsel Absberg am Kleinen Brombachsee heißen wir Sie in unserer Gaststätte „Biergarten Zum Hafen“ herzlich willkommen.</p>
          </div>
        </div>
        <div className="row mt-4 welcome-images-container">
          <div className="col-md-4 feature-card-container" data-aos="zoom-in">
            <div className="card feature-card">
               <div className="card-body d-flex flex-column justify-content-center align-items-center">
                <FaBirthdayCake className="feature-icon" />
                <h5 className="card-title">Feste & Feiern</h5>
                <p className="card-text">Richten wir professionell aus – zum Beispiel unter unserem Tentickle-Zelt auf der Sonnenterrasse!</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 feature-card-container" data-aos="zoom-in" data-aos-delay="200">
            <div className="card feature-card">
             <div className="card-body d-flex flex-column justify-content-center align-items-center">
                <FaBus className="feature-icon" />
                <h5 className="card-title">(Bus-)Reisegruppen</h5>
                <p className="card-text">Wir begrüßen Sie gerne – auf Wunsch mit individuellen Menüs. <a href="#contact">Kontaktieren Sie uns!</a></p>
              </div>
            </div>
          </div>
          <div className="col-md-4 feature-card-container" data-aos="zoom-in" data-aos-delay="400">
            <div className="card feature-card">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
                <FaDog className="feature-icon" />
                <h5 className="card-title">Hunde und ihre Besitzer</h5>
                <p className="card-text">Herzlich willkommen – in nächster Nähe zum Hundestrand am Kleinen Brombachsee!</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 welcome-images-container">
          <div className="col-md-4" data-aos="fade-up" style={{marginBottom: '1rem'}}>
            <img src={image5} alt="Bratwurst" className="Leckere Bratwurst am Brombachsee" style={{ height: 'auto'}} />
          </div>
          <div className="col-md-4" data-aos="fade-up" style={{marginBottom: '1rem'}} >
            <img src={image2} alt="Bratwurst"style={{ height: 'auto'}} className="Gruppenbesuche am Biergarten zum Hafen" />
          </div>
          <div className="col-md-4" data-aos="fade-up" style={{objectFit: 'fill'}}>
            <img src={image4} alt="Biergarten Tafel" className="Leckere Speisen im Biergarten am Brombachsee" style={{ height: 'auto' }} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
