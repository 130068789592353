import './top.css'
import  { Navbar, Hero } from '../../../components'

const Top = () => {
    
return   (

<div className="top">

    <Navbar />
    <Hero />

</div>
)
} 

export default Top