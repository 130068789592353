import React from 'react';
import './Datenschutz.css';

const Datenschutz = () => {
  return (
    <div className="datenschutz-container">
      <h1>Datenschutz</h1>
      <p>Hier kommen die Informationen zum Datenschutz.</p>
    </div>
  );
};

export default Datenschutz;
