import React, { useEffect } from 'react';
import Slider from 'react-slick';
import './aboutus.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Biergarten_mit_Sandkasten from '../../assets/3449760_biergarten-mit-sandkasten.jpg';
import AnotherImage from '../../assets/3449754_restaurant-terrasse-seeblick.jpg'; // Füge zusätzliche Bilder hinzu
import Gastaette from '../../assets/3450100_restaurant-zum-hafen-absberg.jpg'

import AiImage from '../../assets/groupai.webp'

const AboutUs = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,  // Dauer der Animation in Millisekunden
      once: true,      // Animation nur einmal abspielen
    });
  }, []);

  const settings = {
    dots: false,  // Navigationsdots anzeigen
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,  // Automatisches Scrollen aktivieren
    autoplaySpeed: 6000,  // Zeit in Millisekunden zwischen den Slides
    arrows: false,  // Pfeile ausblenden
  };

  return (
    <section id="about" className="about-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6" data-aos="fade-up">
            <div className="about-content">
              <h2 className="section-title">Gaststätte „Biergarten zum Hafen“</h2>
              <h3 className="section-subtitle">Essen, trinken und Seeblick genießen – direkt auf der Badehalbinsel Absberg am Kleinen Brombachsee!</h3>
              <p className="section-description1">
               
                Die <strong>Gaststätte „Biergarten Zum Hafen“ in Absberg</strong> ist der ideale Ort für alle, die einen abwechslungsreichen Tag am See verbringen oder einfach nur entspannen möchten. Wir sind nicht nur ein Biergarten, sondern verstehen uns als <strong>Freiluft-Restaurant</strong>.
              </p>
            </div>
          </div>
          <div className="col-md-6" data-aos="zoom-in">
            <Slider {...settings}>
              <div className="about-image-container">
                <img src={Biergarten_mit_Sandkasten} alt="Gaststätte Biergarten" className="Restaurant mit Sandkasten" />
              </div>
              <div className="about-image-container" style={{ objectFit: 'fill', minWidth: '200%' }}>
                <img src={AnotherImage} alt="Another view" className="Restaurant Terasse mit Seeblick"  />
              </div>
              <div className="about-image-container" style={{height: '100vh'}}>
                <img src={Gastaette} alt="Another view" className="Restaurant Terasse mit Seeblick"  />
              </div>

              {/* Füge hier weitere Bilder hinzu */}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
