import React from 'react';
import './navbar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import logo from '../../assets/logo.png';
import { FaHome, FaUtensils, FaWater, FaUsers, FaEnvelope, FaBars } from 'react-icons/fa';
import { FaPhoneVolume } from "react-icons/fa6";

const Navbar = () => {

  // Funktion, um das Navbar-Collapse zu schließen
  const handleNavItemClick = () => {
    const navbarToggler = document.getElementById('navbarNav');
    if (navbarToggler.classList.contains('show')) {
      navbarToggler.classList.remove('show');
    }
  };

  return (
    <nav className="navbar navbar-dark">
      <a href="/" className="navbar-brand">
        <img src={logo} alt="Logo" className="navbar-logo" />
      </a>

      <div className="navbar-actions">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ color: 'white', fontSize: '1.7rem', fontWeight: '900' }}
        >
          <FaBars className="Navbar-toggler-icon" />
        </button>
        <a href="tel:0157 718 42593" className="phone-link">
          <FaPhoneVolume className="phone-icon" style={{ marginRight: '10px' }} />
          <span>0157 718 42593</span>
        </a>
      </div>

      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <a href="#welcome" className="nav-link" onClick={handleNavItemClick}>
              <FaHome className="nav-icon" />
              <span className="nav-text">Home</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="#foodanddrink" className="nav-link" onClick={handleNavItemClick}>
              <FaUtensils className="nav-icon" />
              <span className="nav-text">Essen & Trinken</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="#active" className="nav-link" onClick={handleNavItemClick}>
              <FaWater className="nav-icon" />
              <span className="nav-text">Aktivitäten</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="#groups" className="nav-link" onClick={handleNavItemClick}>
              <FaUsers className="nav-icon" />
              <span className="nav-text">Gruppen</span>
            </a>
          </li>
            <li className="nav-item">
              <a href="/jobs" className="nav-link" onClick={handleNavItemClick}>
                <FaUsers className="nav-icon" />
                <span className="nav-text">Jobs</span>
              </a>
          </li>
          <li className="nav-item">
            <a href="#contact" className="nav-link" onClick={handleNavItemClick}>
              <FaEnvelope className="nav-icon" />
              <span className="nav-text">Kontakt</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
