
import '../hero/hero.css'
import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import image1 from '../../assets/3449736_bratwurst.jpg';
import image2 from '../../assets/2631734_hafen-absberg_02.jpg';
import image3 from '../../assets/3449732_biergarten-tafel.jpg';
import image4 from '../../assets_stock/stock-party.jpg';
import Navbar from '../navbar/navbar';



const cardsData = [
   {
      title: "Gruppen",
      description: "Mit Gruppe anreisen – wir helfen Ihnen gerne! Kontaktieren Sie uns noch heute, um mehr über unsere ",
      imgSrc: image4,
      link: "#booking"
    },
    {
      title: "Freiluft-Gaststätte",
      description: "Machen Sie es sich gemütlich – im Biergarten oder auf der Sonnenterrasse mit Seeblick.",
      imgSrc: image3,
      link: "#freiluft"
    },
    {
      title: "Essen & Trinken",
      description: "Frisch, fränkisch, saisonal: Genießen Sie vielfältige Gerichte, Brotzeiten oder Kaffee und Kuchen.",
      imgSrc: image1,
      link: "#foodanddrink"
    },
  {
      title: "Angebot für Aktive",
      description: "Am Hafen Absberg legt man zu Freizeitspaß ab – Tret- und Ruderboote leiht man gleich bei uns!",
      imgSrc: image2,
      link: "#active" 
    }
  ];

const herocards = () => {
  return (
    <main>
    <section className="cards-container">
      {cardsData.map((card, index) => (
        <a href={card.link} style={{ textDecoration: 'none' }} key={index}> 
          <div className="card">
            <img src={card.imgSrc} alt={card.title} />
            <div className="card-content">
              <h3>{card.title}</h3>
              <p>{card.description}</p>
            </div>
          </div>
        </a>
      ))}
    </section>
  </main>

  );
}

export default herocards