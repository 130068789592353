import React, { useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import './groups.css';
import anotherGroupImage from '../../assets/8098491_hafen-absberg_02.jpg'; // Beispielbild
import AOS from 'aos';
import 'aos/dist/aos.css';
import image4 from '../../assets_stock/stock-party.jpg';
import gruppensalon from '../../assets/IMG-20240729-WA0021.jpg';
import gruppe from '../../assets/IMG-20240729-WA0032.jpg';
import food from '../../assets/IMG-20240729-WA0033.jpg';
import glass from '../../assets/IMG-20240729-WA0041.jpg';
import glass2 from '../../assets/IMG-20240729-WA0045.jpg';
import blurr from '../../assets/IMG-20240729-WA0055.jpg';
import harbor from '../../assets/IMG-20240729-WA0064.jpg';

const Groups = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,  // Dauer der Animation in Millisekunden
      once: true,     // Animation nur einmal abspielen
    });
  }, []);

  return (
    <section className="groups-section" id="groups">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12" data-aos="fade-up">
            <h2 className="groups-title text-left">Unvergessliche Gruppenfeiern mit Seeblick!</h2>
            <p className="groups-text">
              Planen Sie eine Veranstaltung, die unvergesslich bleiben soll? Unsere Gaststätte ist der <strong>perfekte Ort</strong> für Ihre Feier – ob <strong>Firmenfeiern</strong>, <strong>Geburtstage</strong> oder besondere Anlässe. Mit großzügigen Räumlichkeiten und einer einladenden Atmosphäre bieten wir den idealen Rahmen für Ihr Event.
            </p>
            <div className="groups-boxes">
              <div className="groups-box" data-aos="fade-up">
                <i className="fas fa-map-marker-alt groups-icon"></i>
                <h4 className="groups-subtitle">Perfekter Ort</h4>
                <p className="groups-text">Unsere Gaststätte bietet den idealen Rahmen für Ihre Veranstaltung.</p>
              </div>
              <div className="groups-box" data-aos="fade-up">
                <i className="fas fa-users groups-icon"></i>
                <h4 className="groups-subtitle">Vielseitig</h4>
                <p className="groups-text">Ob Firmenfeiern, Geburtstage oder besondere Anlässe – wir sind vorbereitet.</p>
              </div>
              <div className="groups-box" data-aos="fade-up">
                <i className="fas fa-building groups-icon"></i>
                <h4 className="groups-subtitle">Großzügige Räumlichkeiten</h4>
                <p className="groups-text">Genügend Platz für Ihre Gäste in einer einladenden Atmosphäre.</p>
              </div>
              <div className="groups-box" data-aos="fade-up">
                <i className="fas fa-user-tie groups-icon"></i>
                <h4 className="groups-subtitle">Erfahrenes Team</h4>
                <p className="groups-text">Unterstützung bei der Planung und Durchführung Ihres Events.</p>
              </div>
              <div className="groups-box" data-aos="fade-up">
                <i className="fas fa-cogs groups-icon"></i>
                <h4 className="groups-subtitle">Maßgeschneiderte Lösungen</h4>
                <p className="groups-text">Individuell auf Ihre Bedürfnisse zugeschnittene Angebote.</p>
              </div>
              <div className="groups-box" data-aos="fade-up">
                <i className="fas fa-star groups-icon"></i>
                <h4 className="groups-subtitle">Exklusive Gruppenangebote</h4>
                <p className="groups-text">Spezielle Pakete und Menüs für große Gruppen.</p>
              </div>
            </div>
            <a href="#contact" className="cta-button-groups">Jetzt für Gruppen buchen</a>
          </div>
          <div className="col-md-12" data-aos="zoom-in">
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-50 mx-auto groups-carousel-image"
                  src={image4}
                  alt="Gruppenbuchung"
                />
              </Carousel.Item>
            
              <Carousel.Item>
                <img
                  className="d-block w-50 mx-auto groups-carousel-image"
                  src={gruppensalon}
                  alt="Gruppenbuchung"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-50 mx-auto groups-carousel-image"
                  src={gruppe}
                  alt="Gruppenbuchung"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-50 mx-auto groups-carousel-image"
                  src={food}
                  alt="Gruppenbuchung"
                />
              </Carousel.Item>
           
              <Carousel.Item>
                <img
                  className="d-block w-50 mx-auto groups-carousel-image"
                  src={harbor}
                  alt="Gruppenbuchung"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-50 mx-auto groups-carousel-image"
                  src={blurr}
                  alt="Gruppenbuchung"
                />
              </Carousel.Item>
              {/* Weitere Bilder hinzufügen */}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Groups;
