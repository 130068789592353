import React, { useEffect } from 'react';
import './foodanddrinks.css'; // Pfad zur CSS-Datei
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import { FaUtensils, FaSeedling, FaHamburger, FaFish, FaDrumstickBite, FaLeaf } from 'react-icons/fa';
import image1 from '../../assets2/WhatsApp Image 2024-09-16 at 10.51.44.jpeg';
import image2 from '../../assets/3449736_bratwurst.jpg';
import image3 from '../../assets2/WhatsApp Image 2024-09-16 at 10.51.35.jpeg';
import image4 from '../../assets2/WhatsApp Image 2024-09-16 at 10.51.33.jpeg';
import chefImage from '../../assets/3449744_raeucherofen.jpg';
import image6 from '../../'
import AOS from 'aos';
import 'aos/dist/aos.css';

const FoodAndDrink = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,  // Dauer der Animation in Millisekunden
      once: true,      // Animation nur einmal abspielen
    });
  }, []);

  return (
    <section id="foodanddrink" className="food-and-drink-section-unique">
      <div className="container">
        <div className="row">
          <div className="col-md-6" data-aos="fade-up">
            <div className="food-drink-card-unique">
              <h3 className="food-drink-title-unique"><FaUtensils /> Essen und Trinken</h3>
              <p className="food-drink-description-unique">
                <FaSeedling /> <strong>Frisch, fränkisch, saisonal:</strong> Die Gaststätte „Biergarten zum Hafen“ in Absberg bietet eine abwechslungsreiche Tageskarte und saisonale Angebote.
              </p>
              <p className="food-drink-description-unique">
                <FaHamburger /><strong> Herzhafte Burger, Deftiges vom Grill oder gesunde Salate sind unsere Spezialität.</strong> Für den kleinen Hunger zwischendurch bieten wir leckere Snacks wie Currywurst oder Reiberdatschi mit Apfelmus. Gern servieren wir Ihnen auch Kaffee und Kuchen.
              </p>
              <p className="food-drink-description-unique">
                <strong><FaFish /> Regional:</strong> Unsere Fischspezialitäten stammen aus unseren eigenen Teichen und werden im Räucherofen vor Ort frisch zubereitet. In der Wildsaison bieten wir gerne Spezialitäten aus den heimischen Wäldern und eigener Jagd. Bei unseren Salaten setzen wir auf regionalen Anbau aus dem Brombachtal.
              </p>
              <p className="food-drink-description-unique">
                <strong><FaDrumstickBite /> Fränkisch:</strong> Auf unseren wechselnden Tageskarten finden Sie immer wieder auch fränkische Klassiker – zum Beispiel Bratwürste, Saure Zipfel, Nürnberger Sauerbraten oder hausgemachte Tellerröste.
              </p>
              <p className="food-drink-description-unique">
                <strong><FaLeaf /> Saisonal:</strong> Spargel und Bärlauch im Frühling, Pilze, Karpfen und Enten im Herbst – bei uns genießen Sie Spezialitäten je nach Jahreszeit.
              </p>
            </div>
          </div>
          <div className="col-md-6" id='food-and-drink' data-aos="zoom-in">
            
              <Carousel className="food-drink-carousel-unique">
                <Carousel.Item>
                  <img className="d-block w-100 food-drink-carousel-img-unique" src={image1} alt="First slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100 food-drink-carousel-img-unique" src={image2} alt="Second slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100 food-drink-carousel-img-unique" src={image3} alt="Third slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100 food-and-drink-carousel-img-unique" src ={image4} alt="Third slide"/>
                </Carousel.Item>
              </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FoodAndDrink;
