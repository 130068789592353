import React, { useState, useEffect } from 'react';
import './footer.css'; 
import MapComponent from '../MapComponent/MapComponent'; // Importiere die Map-Komponente
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import partner1 from '../../assets/partner-zvb.png';
import partner2 from '../../assets/partner-nfs.png'; 

const Footer = () => {
  const [weatherData, setWeatherData] = useState(null);

  useEffect(() => {
    AOS.init({
      duration: 800,  // Dauer der Animation in Millisekunden
      once: true,      // Animation nur einmal abspielen
    });
    

    const fetchWeatherData = async () => {
      try {
        const response = await axios.get(`https://api.openweathermap.org/data/2.5/weather?q=Absberg,de&units=metric&appid=${process.env.REACT_APP_OPENWEATHER_API_KEY}`);
        setWeatherData(response.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Wetterdaten:', error);
      }
    };

    fetchWeatherData();
  }, []);
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-section">
      <div className="container">
        <div className="row">
          <div className="col-md-3" >
            <h5>Lage</h5>
            <MapComponent /> {/* Binde hier die Karte ein */}
          </div>
          <div className="col-md-3" >
            <h5>Wetter</h5>
            {weatherData ? (
              <>
                <p>Heute<br />{Math.round(weatherData.main.temp)}°C<br />{weatherData.wind.speed} km/h</p>
                <p>{weatherData.weather[0].description}</p>
                <p className="small">powered by OpenWeather</p>
              </>
            ) : (
              <p>Wetterdaten werden geladen...</p>
            )}
          </div>
          <div className="col-md-3" >
            <h5>Service</h5>
            <p><a href="#anfahrt">Anfahrt</a></p>
            <p><a href="#oeffnungszeiten">Öffnungszeiten</a></p>
            <p><a href="#groups">Gruppenveranstaltungen</a></p>
          </div>
          <div className="col-md-3">
            <h5>Informationen</h5>
            <p>Biergarten „Zum Hafen“<br />Badehalbinsel 1, 91720 Absberg</p>
            <p><a href="tel:+491715874744">0157 718 42593</a></p>
            <p><a href="mailto:gastro.hafen@gmail.com">gastro.hafen@gmail.com</a></p>
          </div>
        </div>
        <div className="row partners" >
          <div className="col-md-12">
            <h5>Unsere Partner</h5>
            <div className="partner-logos">
             <a href="https://www.fraenkisches-seenland.de/"> <img src={partner2} alt="Partner Fraenkisches Seenland" /></a>
             <a href= "https://www.zv-brombachsee.de/gastronomie/biergarten_zum_hafen-507/"> <img src={partner1} alt="Partner Brombachsee" /></a>
            </div>
          </div>
        </div>
        <div className="row legal" >
          <div className="col-md-12">
            <p>© {currentYear} Biergarten „Zum Hafen“</p>
            <p><a href="/Impressum">Datenschutz</a> | <a href="/Impressum">Impressum</a> | <a href="#contact">Kontakt</a> <br></br><br></br> <a href="https://www.exabyte-webservices.com">Entwickelt von Exabyte Webservices</a></p>

          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
