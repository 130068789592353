import React, { useEffect } from 'react';
import Slider from 'react-slick';
import './wateractivities.css';
import { FaWater, FaDog, FaUmbrellaBeach } from 'react-icons/fa'; 
import Tretboot_Brombachsee from '../../assets/8098491_hafen-absberg_02.jpg';
import AnotherImage from '../../assets/3449736_bratwurst.jpg'; // Ein weiteres Bild für das Karussell
import AOS from 'aos';
import 'aos/dist/aos.css';

const WaterActivities = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);
  const settings = {
    dots: false,  // Navigationsdots anzeigen
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,  // Automatisches Scrollen aktivieren
    autoplaySpeed: 6000,  // Zeit in Millisekunden zwischen den Slides
    arrows: false,  // Pfeile ausblenden
    swipe : false,
  };

  return (
    <section id="active" className="water-activities-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6" data-aos="fade-up">
            <div className="water-text-container">
              <h3 className="section-title">
                <FaWater className="icon-large2" /> Aufs Wasser!
              </h3>
              <p className="section-description">
                <FaUmbrellaBeach className="icon-large" /> Die Gaststätte „Biergarten Zum Hafen“ ist Ausgangspunkt, Zwischenstopp und Abschluss für einen abwechslungsreichen Tag auf der Badehalbinsel Absberg.
              </p>
              <p className="section-description">
                Wir vermieten Tretboote für entspannte Runden auf dem Kleinen Brombachsee: Tretboot 15€ je Stunde.
              </p>
              <p className="section-description">
                Nur ein paar Schritte vom Biergarten entfernt liegen der Sandstrand, das Seezentrum Badehalbinsel Absberg und der Segelhafen. Auch für Radler und Spaziergänger, die auf den Wegen rund um den Brombachsee unterwegs sind, ist die Gaststätte „Biergarten Zum Hafen“ eine ideale Einkehrmöglichkeit.
              </p>
              <p className="section-tip">
                <FaDog className="icon-large" /> Tipp: Hundebesitzer finden in nächster Nähe auch einen ausgewiesenen Hundestrand, an dem ihre Vierbeiner im Wasser herumtollen können.
              </p>
              <a href="https://www.fraenkisches-seenland.de/" className="section-link">Mehr zum Seezentrum auf der Badehalbinsel Absberg auf der Website des Fränkischen Seenlandes</a>
            </div>
          </div>
          <div className="col-md-6" data-aos="fade-up">
            
              <div className="image-containerwater">
                <img src={Tretboot_Brombachsee} alt="Tretboot Brombachsee" className="img-fluid water-activities-image" />
              </div>
              
              {/* Füge hier weitere Bilder hinzu, wenn gewünscht */}
                   </div>
        </div>
      </div>
    </section>
  );
};

export default WaterActivities;
