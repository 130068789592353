import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Leaflet Icon Fix for default marker icon not showing up properly in React
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const MapComponent = () => {
  return (
    <MapContainer 
      center={[49.13676151271156, 10.868481324077568]}  // Neue Koordinaten für Badehalbinsel 1, 91720 Absberg
      zoom={13} 
      style={{ height: "400px", maxwidth: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={[49.13677759555736, 10.868393738191843]}>  // Neue Marker-Position
        <Popup>
          Biergarten Zum Hafen <br /> Badehalbinsel 1, 91720 Absberg, Deutschland.
        </Popup>
      </Marker>
    </MapContainer>
  );
}

export default MapComponent;
