import React, { useState, useEffect } from 'react';
import './picturesgallery.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import { FaCameraRetro, FaUtensils, FaBinoculars, FaDog , FaBirthdayCake} from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import images from '../images'; // Importiere die Bilder aus images.js
import './picturesgallery.css';

const descriptions = [
  "Ein Tag am See – im „Biergarten Zum Hafen“",
  "Genießen Sie unsere fränkischen Schmankerln und Snacks!",
  "Schauen Sie mal rein – hier finden Sie 360°-Bilder aus unserem Biergarten!"
];

const PicturesGallery = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const renderCarouselItems = () => {
    return images.map((image, idx) => (
      <Carousel.Item key={idx}>
        <img
          className="d-block w-100 carousel-image"
          src={image}
          alt={`Bild ${idx + 1}`}
        />
      </Carousel.Item>
    ));
  };

  return (
    <section id="pictures-gallery" className="pictures-gallery-section">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-5" data-aos="fade-up">
            <h2 className="gallery-title"><FaCameraRetro /> Fotos aus der Gaststätte „Biergarten Zum Hafen“</h2>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-6" data-aos="zoom-in">
            <div className="carousel-container">
              <Carousel activeIndex={index} onSelect={handleSelect} className="carousel-modern">
                {renderCarouselItems()}
              </Carousel>
              
              </div>
              <div className="carousel-description text-center">
                <p>{descriptions[index]}</p>
            </div>
          </div>
          <div className="col-md-6 text-container" data-aos="fade-up">
            <div className="text-modern">
            <p className="gallery-text">
  <FaUtensils />
Mit seiner Lage direkt am Hafen ist unsere Gaststätte eine ideale Einkehrmöglichkeit für alle, die einen abwechslungsreichen Tag am See verbringen.
</p>

              <p className='gallery-text'><FaBirthdayCake />Unsere Gaststätte am Hafen bietet den idealen Rahmen für unvergessliche Gruppenveranstaltungen – ob Firmen-Events, private Feiern oder Busreisen – bei uns finden Sie genau die richtige Atmosphäre für jeden Anlass!</p>
              <p className="gallery-text"><FaBinoculars />Neugierig? Bei Interesse freut sich unser gesamtes Biergarten-Team darauf, SIe bald persönlich bei uns am Hafen begrüßen zu dürfen. Schauen Sie vorbei und erleben Sie den einzigartigen Charme unserer Gastätte mit Seeblick - wir sind gespannt auf ihren Besuch!</p>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default PicturesGallery;
