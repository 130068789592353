import React, { useState } from 'react';
import { Container, Card, Button } from 'react-bootstrap';
import { FaConciergeBell, FaHandsHelping, FaUtensils, FaUserTie, FaCashRegister, FaBroom, FaSuitcase, FaClock, FaTree, FaUsers, FaCompass } from 'react-icons/fa';
import './Jobs.css';
import image1 from './jessie-mccall-guXX_Wm-wnY-unsplash.jpg';

const Jobs = () => {
  const [selectedJob, setSelectedJob] = useState('');

  const jobs = [
    { title: "Servicekraft", description: "Wir suchen eine freundliche Servicekraft für unseren Biergarten, die unsere Gäste mit einem Lächeln bedient.", icon: <FaConciergeBell size={50} /> },
    { title: "Küchenhilfe", description: "Verstärke unser Team als Küchenhilfe und unterstütze uns bei der Vorbereitung und Zubereitung der Speisen.", icon: <FaHandsHelping size={50} /> },
    { title: "Koch", description: "Werde Teil unseres Küchenteams als Koch und bringe deine Kreativität und Leidenschaft für gutes Essen mit.", icon: <FaUtensils size={50} /> },
    { title: "Chefkoch", description: "Wir suchen einen erfahrenen Chefkoch, der unser Küchenteam anleitet und neue kulinarische Highlights kreiert.", icon: <FaUserTie size={50} /> },
    { title: "Kassierkraft", description: "Unterstütze uns als Kassierkraft und sorge dafür, dass der Bestell- und Bezahlvorgang reibungslos abläuft.", icon: <FaCashRegister size={50} /> },
    { title: "Allgemeine Hilfe", description: "Als Allgemeine Hilfe bist du vielseitig einsetzbar und unterstützt unser Team in verschiedenen Bereichen.", icon: <FaBroom size={50} /> },
    { title: "Arbeiten, wo andere Urlaub machen", description: "Genieße frische Luft, Natur und die idyllische Lage direkt am Brombachsee - dein Arbeitsplatz wird zum täglichen Kurzurlaub!", icon: <FaCompass size={50} /> },
    { title: "Stellvertretender Geschäftsführer/Serviceleiter", description: "Übernimm Verantwortung als Stellvertretender Geschäftsführer/Serviceleiter und sorge für einen reibungslosen Ablauf im täglichen Betrieb und im Servicebereich.", icon: <FaSuitcase size={50} /> },
    { title: "Gemeinsam Stark", description: "Bei uns wird Teamwork großgeschrieben. Flache Hierarchien und direkte Kommunikation sorgen für eine entspannte Arbeitsatmosphäre.", icon: <FaUsers size={50} /> },
  ];

  return (
    <Container className="mt-5 jobs-section">
      <div className="jobs-header mb-5">
        <h1>Karriere im Biergarten "Zum Hafen"</h1>
        <p className="jobs-intro">
          Werden Sie Teil unseres Teams! Ob in der Küche, im Service oder als Reinigungskraft – 
          wir bieten spannende Jobmöglichkeiten in einer idyllischen Umgebung direkt am Brombachsee. 
          Bewerben Sie sich jetzt und genießen Sie die Arbeit in einer entspannten, freundlichen Atmosphäre.
        </p>
        <img src={image1} alt="Teamwork" className="img-fluid rounded" style={{ maxHeight: '300px' }} />
      </div>

      <h1 className="jobs-title">Aktuelle Jobangebote</h1>
      <div className="masonry-grid">
        {jobs.map((job, index) => (
          <div key={index} className="masonry-item">
            <Card className="job-card h-100">
              <div className="text-center mt-3">
                {job.icon}
              </div>
              <Card.Body>
                <Card.Title>{job.title}</Card.Title>
                <Card.Text>{job.description}</Card.Text>
                <Button variant="primary" onClick={() => setSelectedJob(job.title)}>
                  Mehr erfahren
                </Button>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>

      <div id="contact-form" className="contact-form-section mt-5">
        <h2 className="text-center">Kontaktieren Sie uns für mehr Informationen</h2>
        <form className="mt-4" method="POST" action="send_email.php">
          <div className="form-group">
            <label htmlFor="formName">Name</label>
            <input type="text" className="form-control" id="formName" name="name" placeholder="Ihr Name" required />
          </div>
          <div className="form-group">
            <label htmlFor="formEmail">Email</label>
            <input type="email" className="form-control" id="formEmail" name="email" placeholder="Ihre Email" required />
          </div>
          <div className="form-group">
            <label htmlFor="formPosition">Gewünschte Position</label>
            <select className="form-control" id="formPosition" name="position" value={selectedJob} onChange={(e) => setSelectedJob(e.target.value)} required>
              <option value="">Bitte wählen</option>
              {jobs.map((job, index) => (
                <option key={index} value={job.title}>{job.title}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="formMessage">Nachricht</label>
            <textarea className="form-control" id="formMessage" name="message" rows="4" placeholder="Ihre Nachricht" required></textarea>
          </div>
          <Button type="submit" className="btn btn-primary">Bewerbung absenden</Button>
        </form>
      </div>
    </Container>
  );
};

export default Jobs;
