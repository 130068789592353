import React, { useEffect } from 'react';
import Slider from 'react-slick';
import './testimonials.css';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa'; 
import AOS from 'aos';
import 'aos/dist/aos.css';

const Testimonials = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  const testimonials = [
    {
      quote: "Ein wunderbarer Ort zum Entspannen und Genießen. Hervorragendes Essen und eine tolle Atmosphäre.",
      author: "Anna Müller",
      rating: 5
    },
    {
      quote: "Tolle Lage, exzellenter Service und fantastische Speisen. Wir kommen auf jeden Fall wieder!",
      author: "Johann Schmidt",
      rating: 5
    },
    {
      quote: "Ausgezeichneter Service und wunderbares Ambiente. Ein unvergessliches Erlebnis!",
      author: "Miriam Köhler",
      rating: 4
    },
    {
      quote: "Wirklich beeindruckend, vom Essen bis zum Service. Jeder Besuch ist ein Genuss!",
      author: "Peter Becker",
      rating: 5
    }
  ];

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <>
        {[...Array(fullStars)].map((_, i) => (
          <FaStar key={`full-${i}`} className="star-icon" />
        ))}
        {halfStar && <FaStarHalfAlt className="star-icon" />}
        {[...Array(emptyStars)].map((_, i) => (
          <FaRegStar key={`empty-${i}`} className="star-icon" />
        ))}
      </>
    );
  };

  const settings = {
    dots: false,  // Entfernt die Dots
    arrows: false,  // Entfernt die Navigationspfeile
    infinite: true,
    speed: 5000,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,  // Automatisches Scrollen aktivieren
    autoplaySpeed: 3000,  // Zeit in Millisekunden zwischen den Slides
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  return (
    <section id="testimonials" className="testimonials-section">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center" data-aos="fade-up">
            <h2 className="testimonials-title">Was unsere Gäste sagen</h2>
          </div>
        </div>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-card" data-aos="fade-up" data-aos-delay={`${index * 200}`}>
              <div className="star-rating">
                {renderStars(testimonial.rating)}
              </div>
              <p className="testimonial-quote">"{testimonial.quote}"</p>
              <p className="testimonial-author"><strong>- {testimonial.author}</strong></p>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
